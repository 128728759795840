<template>
  <div>
    <div class="w-[90%] mt-2 ml-[5%]">
      <div class="p-6 bg-[#ff9b43] shadow-lg" style="border-radius: 12px">
        <div v-if="items.length >= limit">
          <h2
            v-if="
              winner.name == userInfo.name &&
              winner.zhuang == userInfo.zhuang &&
              winner.unit == userInfo.unit &&
              winner.room == userInfo.room
            "
            class="text-12 mb-4 text-center mb-10"
          >
            嗨，你被抽中啦！
          </h2>
          <h2 v-else class="text-12 mb-4 text-center mb-10">
            嗨，你未被抽中！
          </h2>
        </div>
        <h2 v-if="items.length < limit" class="text-12 mb-4 text-center mb-10">
          抓阄还未开始
        </h2>
        <h2
          class="text-[24px] mb-1 font-bold text-center"
          style="color: #726549"
        >
          5号楼前17号车位抓阄
        </h2>
        <div class="text-700 text-center" style="color: white">
          {{ items.length }}/{{ limit }}已参与，参与者达到{{
            limit
          }}人时自动抓阄
        </div>
        <div
          v-if="!userInfo.mobile && items.length < 13"
          @click="showSubmit = true"
          class="mt-4 bg-black text-white px-4 py-1 text-center"
          style="border-radius: 12px; color: #ff9b43"
        >
          参与抓阄
        </div>
      </div>
    </div>

    <div v-if="showResult" class="w-screen p-4">
      <div
        class="bg-white rounded-lg pl-1 pr-1 overflow-y-auto"
        style="max-height: 200px"
      >
        <ul class="space-y-1">
          <li v-for="(item, index) in items" :key="index" class="rounded-md">
            <div class="pb-2 flex text-[16px]">
              <div class="w-4/5">
                <div>{{ item.name }}（{{ item.mobile }}）</div>
                <div class="mt-1">
                  {{ item.zhuang }}幢{{ item.unit }}单元{{ item.room }}室
                </div>
                <div style="color: gray" class="mt-1">
                  {{ formatDate(new Date(item.time)) }}
                </div>
              </div>
              <div v-if="items.length >= limit"  class="flex items-center justify-center w-1/5 text-[16px]">
                <div
                  v-if="item.win == 0"
                 
                  style="color: #ff9b43"
                >
                  未抽中
                </div>
                <div
                  v-if="item.win == 1"
                  style="color: #ff9b43"
                >
                  已抽中
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="mt-2 w-screen text-center text-[12px]" style="color: gray">
      <div v-if="showResult" @click="showResult = !showResult">隐藏结果</div>
      <div v-if="!showResult" @click="showResult = !showResult">显示结果</div>
    </div>

    <!-- 弹窗背景 -->
    <div
      @click="showSubmit = !showSubmit"
      v-if="showSubmit"
      class="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
    >
      <!-- 弹窗内容 -->
      <div
        style="border-radius: 12px; border: 1px"
        @click.stop="() => {}"
        class="bg-white rounded-lg shadow-lg p-6 w-full max-w-md mx-4"
      >
        <h2 class="text-xl font-bold mb-4">参与抓阄</h2>
        <!-- 表单 -->
        <form>
          <div class="mb-4 flex items-center">
            <label class="w-15 block text-sm font-bold mr-2" for="name"
              >姓名</label
            >
            <input
              class="shadow appearance-none border rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline flex-grow"
              id="name"
              type="text"
              v-model="form.name"
              placeholder="请输入姓名"
            />
          </div>
          <div class="mb-4 flex items-center">
            <label class="w-15 block text-sm font-bold mr-2" for="name"
              >手机号</label
            >
            <input
              class="shadow appearance-none border rounded py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline flex-grow"
              ref="name"
              type="number"
              v-model="form.mobile"
              placeholder="请输入手机号"
            />
          </div>
          <div class="mb-4 flex items-center">
            <label class="w-15 block text-sm font-bold mr-2" for="gender"
              >幢</label
            >
            <select
              v-model="form.zhuang"
              class="shadow appearance-none border rounded py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline flex-grow"
            >
              <option :value="1">1</option>
              <option :value="2">2</option>
              <option :value="3">3</option>
              <option :value="4">4</option>
              <option :value="5">5</option>
            </select>
          </div>
          <div class="mb-4 flex items-center">
            <label class="w-15 block text-sm font-bold mr-2" for="gender"
              >单元</label
            >
            <select
              v-model="form.unit"
              class="shadow appearance-none border rounded py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline flex-grow"
            >
              <option :value="1">1</option>
              <option :value="2">2</option>
              <option :value="3">3</option>
              <option :value="4">4</option>
            </select>
          </div>
          <div class="mb-4 flex items-center">
            <label class="w-15 block text-sm font-bold mr-2" for="name"
              >房间号</label
            >
            <input
              class="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline flex-grow"
              id="name"
              type="number"
              v-model.number="form.room"
              placeholder="请输入房间号"
            />
          </div>
          <div class="flex justify-end">
            <div
              @click="showSubmit = false"
              style="background-color: red"
              class="mr-10 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
            >
              取消
            </div>
            <div
              @click="submit"
              style="background-color: #ff9b43"
              class="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
            >
              提交
            </div>
          </div>
        </form>
      </div>
    </div>

    <div
      v-if="showNotify"
      class="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
    >
      <!-- 弹窗内容 -->
      <div class="bg-white rounded-lg shadow-lg p-6 w-full max-w-md mx-4">
        <h2 class="text-xl font-bold mb-4">提示</h2>
        <p class="mb-4">{{ notifyText }}</p>
        <div class="flex justify-end">
          <button
            @click="showNotify = false"
            style="background-color: #ff9b43"
            class="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            确定
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="!wechat"
      class="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-100"
    >
      <!-- 弹窗内容 -->
      <div class="bg-white rounded-lg shadow-lg p-6 w-full max-w-md mx-4">
        <h2 class="text-xl font-bold mb-4">提示</h2>
        <p class="mb-4">请在微信内打开</p>
      </div>
    </div>
  </div>
</template>

<script>
import { participate, participateList } from "@/api/participate.js";
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: "App",
  components: {
    // HelloWorld
  },
  data() {
    return {
      wechat: false,
      winner: {},
      limit: 13,
      userInfo: {},
      showNotify: false,
      notifyText: "",
      form: {
        name: undefined,
        mobile: undefined,
        zhuang: 5,
        unit: undefined,
        room: undefined,
      },
      showSubmit: false,
      showResult: true,
      items: [
        // {
        //   name: "宋江",
        //   mobile: "137****3619",
        //   zhuang: 5,
        //   unit: 2,
        //   room: 101,
        //   time: 17032273812,
        // },
      ],
    };
  },
  mounted() {
    this.load();
    if (localStorage.getItem("u")) {
      this.userInfo = JSON.parse(localStorage.getItem("u"));
    }
    this.wechat = this.isWeChatBrowser();
  },
  methods: {
    isWeChatBrowser() {
      // 获取当前浏览器的 User-Agent
      const userAgent = navigator.userAgent.toLowerCase();
      // 检查 User-Agent 是否包含 'micromessenger'
      return userAgent.indexOf("micromessenger") !== -1;
    },
    load() {
      participateList().then((resp) => {
        this.items = resp.data;
        for (var i = 0; i < resp.data.length; i++) {
          var tmp = resp.data[i];
          if (tmp.win == 1) {
            this.winner = tmp;
          }
        }
      });
    },
    submit() {
      participate(this.form).then((resp) => {
        if (resp.code != 0) {
          this.notifyText = resp.msg;
          this.showNotify = true;
          return;
        }
        localStorage.setItem("u", JSON.stringify(this.form));
        this.userInfo = this.form;
        this.load();
        this.showSubmit = false;
      });
    },
    formatDate: (date) => {
      function padZero(num) {
        return num < 10 ? "0" + num : num;
      }

      var month = padZero(date.getMonth() + 1);
      var day = padZero(date.getDate());
      var hours = padZero(date.getHours());
      var minutes = padZero(date.getMinutes());
      var seconds = padZero(date.getSeconds());

      return `${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  },
};
</script>

<style>
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>